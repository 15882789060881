import React from 'react';
import Link from 'next/link';
import Image from 'next/image';

const MenuLinks = ({ toggleModal }) => {
  return (
    <div className="menu-links">
      <div className="category-title">
        <div className="title-background">Home</div>
      </div>
      <Link href="/" passHref>
        <a onClick={toggleModal}>
          <Image src="/assets/pinx-circle-logo.png" alt="Pinx Circle Logo" width={30} height={30} />
          Home
        </a>
      </Link>

      <div className="category-title">
        <div className="title-background">BUY $PINX</div>
      </div>
      <Link href="/swap" passHref>
        <a onClick={toggleModal}>
          <Image src="/assets/swap.gif" alt="Swap on Our Platform" width={30} height={30} />
          Swap SOL and PINX
        </a>
      </Link>
      <Link href="https://dexscreener.com/solana/6m32vkef22fzvv2nc69wnabdax7wdn4zsggwjujurqev" passHref>
        <a onClick={toggleModal} target="_blank" rel="noopener noreferrer">
          <Image src="/assets/buy.png" alt="Buy on Dexscreener" width={30} height={30} />
          Buy PINX on Dexscreener
        </a>
      </Link>
      <Link href="/#steps" passHref>
        <a onClick={toggleModal}>
          <Image src="/assets/guide-book.png" alt="How to Buy" width={30} height={30} />
          How to Buy
        </a>
      </Link>

      <div className="category-title">
        <div className="title-background">Token Info</div>
      </div>

      <Link href="/tokenomics" passHref>
        <a onClick={toggleModal}>
          <Image src="/assets/whitepaper.png" alt="Whitepaper" width={30} height={30} />
          Whitepaper
        </a>
      </Link>

      <Link href="/tokenomics" passHref>
        <a onClick={toggleModal}>
          <Image src="/assets/tokenomics.gif" alt="Tokenomics" width={30} height={30} />
          Tokenomics
        </a>
      </Link>

      <Link href="/roadmap" passHref>
        <a onClick={toggleModal}>
          <Image src="/assets/roadmap.png" alt="Roadmap" width={30} height={30} />
          Roadmap
        </a>
      </Link>

      <div className="category-title">
        <div className="title-background">Companies</div>
      </div>
      <Link href="/companies" passHref>
        <a onClick={toggleModal}>
          <Image src="/assets/company.png" alt="Companies" width={30} height={30} />
          Companies
        </a>
      </Link>

      <Link href="/news" passHref>
        <a onClick={toggleModal}>
          <Image src="/assets/news.png" alt="News" width={30} height={30} />
          News
        </a>
      </Link>

      <div className="category-title">
        <div className="title-background">Support</div>
      </div>
      <Link href="/support" passHref>
        <a onClick={toggleModal}>
          <Image src="/assets/technical-support.png" alt="Technical Support" width={30} height={30} />
          Support
        </a>
      </Link>

      <Link href="/about" passHref>
        <a onClick={toggleModal}>
          <Image src="/assets/information.png" alt="About" width={30} height={30} />
          About
        </a>
      </Link>

      <style jsx>{`
        .menu-links {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          overflow: hidden;
        }

        .category-title {
          position: relative;
          width: 100%;
          text-align: center;
          margin: 15px 0 10px 0;
          padding: 0 20px;
        }

        .title-background {
          font-size: 2vw;
          color: lightgrey;
          font-style: italic;
          font-weight: 600;
          background-color: black;
          padding: 5px 10px;
          z-index: 1;
          position: relative;
          display: inline-block;
          text-shadow: 0 0 8px rgba(255, 255, 255, 0.8);
        }

        .category-title::before, .category-title::after {
          content: "";
          position: absolute;
          top: 50%;
          width: 40%;
          height: 1px;
          background-color: lightgrey;
          z-index: 0;
        }

        .category-title::before {
          left: 0;
          margin-right: 10px;
        }

        .category-title::after {
          right: 0;
          margin-left: 10px;
        }

        .menu-links a {
          display: flex;
          align-items: center;
          gap: 10px;
          font-size: 2.5vw; /* Font size larger than category title */
          padding: 4px 20px; /* Adjusted padding */
          width: 100%;
          text-align: left;
          background: linear-gradient(to bottom, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0));
          color: white;
          border-top: 0.2px solid rgba(255, 255, 255, 0.1);
          border-bottom: 0.2px solid rgba(255, 255, 255, 0.1);
          transition: color 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease;
        }

        .menu-links a:first-child {
          border-top: none;
        }

        .menu-links a:last-child {
          border-bottom: none;
        }

        /* Add hover effect */
        .menu-links a:hover {
          color: #ff69b4;
          transform: translateY(2px);
          background: linear-gradient(to bottom, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0));
        }

        @media (max-width: 1000px) {
          .title-background {
            font-size: 3vw;
          }

          .menu-links a {
            font-size: 3.5vw;
          }
        }

        @media (max-width: 800px) {
          .title-background {
            font-size: 4vw;
          }

          .menu-links a {
            font-size: 4.5vw;
          }
        }

        @media (max-width: 600px) {
          .title-background {
            font-size: 5vw;
          }

          .menu-links a {
            font-size: 5.5vw;
          }
        }
      `}</style>
    </div>
  );
};

export default MenuLinks;
