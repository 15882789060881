import React, { useState } from 'react';
import Link from 'next/link';
import Image from 'next/image';
import ConnectWalletButton from '../Account/ConnectWalletButton';
import { usePinxPrice } from '../../context/PinxPriceContext';

const LaptopNavbar: React.FC = () => {
  const { price: pinxPrice } = usePinxPrice();

  const [isMarketHovered, setIsMarketHovered] = useState(false);
  const [isBuyHovered, setIsBuyHovered] = useState(false);
  const [isSupportHovered, setIsSupportHovered] = useState(false);
  const [isCompaniesHovered, setIsCompaniesHovered] = useState(false);
  const [isNewsHovered, setIsNewsHovered] = useState(false);
  const [isAboutHovered, setIsAboutHovered] = useState(false);

  return (
    <div className="nav-holder">
      <div className="logo-nav-container">
        <div className="logo-text">
          <Link href="/" passHref>
            <a className="logo-text">PINX Global</a>
          </Link>
        </div>
        <div className="nav-separator"></div> 
        <div className="nav-links">

            <div className={`nav-dropdown-container ${isBuyHovered ? 'modalSelected' : ''}`}
              onMouseEnter={() => setIsBuyHovered(true)}
              onMouseLeave={() => setIsBuyHovered(false)}>
              Buy PINX
              <svg className={`nav-dropdown-arrow ${isBuyHovered ? 'rotate' : ''}`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                <path d="M5 8l5 5 5-5H5z"/>
              </svg>
              {isBuyHovered && (
                <div className="nav-dropdown-content">


                    <Link href="/swap">
                    <a style={{ textDecoration: 'none' }}>
                        <div className="nav-body-item">
                        <div className="dropdown-icon">
                          <Image
                            src="/assets/swap.gif"
                            alt="Swap Icon"
                            width={40}
                            height={40}
                          />
                        </div>
                        <div className="dropdown-content">
                            <div className="dropdown-title">
                            Exchange SOL and PINX on Our Platform
                            </div>
                            <div className="dropdown-subtitle">
                            Effortlessly swap between SOL and PINX with our secure and user-friendly platform.
                            </div>
                        </div>
                        <div className="dropdown-svg">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                            <path d="M8 5l5 5 -5 5V5z" />
                            </svg>
                        </div>
                        </div>
                    </a>
                    </Link>


                    <Link href="https://dexscreener.com/solana/6m32vkef22fzvv2nc69wnabdax7wdn4zsggwjujurqev" passHref>
                        <a style={{ textDecoration: 'none' }} target="_blank" rel="noopener noreferrer">
                            <div className="nav-body-item">
                            <div className="dropdown-icon">
                                <Image src="/assets/buy.png" alt="Dexscreener Icon" width={40} height={40} />
                            </div>
                            <div className="dropdown-content">
                                <div className="dropdown-title" style={{ cursor: 'pointer' }}>
                                Buy PINX on Dexscreener
                                </div>
                                <div className="dropdown-subtitle">
                                Trade PINX tokens directly on Dexscreener for real-time market rates.
                                </div>
                            </div>
                            <div className="dropdown-svg">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                <path d="M8 5l5 5 -5 5V5z" />
                                </svg>
                            </div>
                            </div>
                        </a>
                      </Link>

                    <Link href="/#steps">
                        <a style={{ textDecoration: 'none' }}>
                        <div className="nav-body-item">
                            <div className="dropdown-icon">
                            <Image src="/assets/guide-book.png" alt="Guide Icon" width={40} height={40} />
                            </div>
                            <div className="dropdown-content">
                            <div className="dropdown-title">
                                How to Buy PINX: 3 Step Guide
                            </div>
                            <div className="dropdown-subtitle">
                                Learn how to purchase PINX in three simple steps.
                            </div>
                            </div>
                            <div className="dropdown-svg">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                <path d="M8 5l5 5 -5 5V5z" />
                            </svg>
                            </div>
                        </div>
                        </a>
                    </Link>
                    </div>


              )}
            </div>


            <div className={`nav-dropdown-container ${isMarketHovered ? 'modalSelected' : ''}`}
            onMouseEnter={() => setIsMarketHovered(true)}
            onMouseLeave={() => setIsMarketHovered(false)}>
            Token Info
            <svg className={`nav-dropdown-arrow ${isMarketHovered ? 'rotate' : ''}`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                <path d="M5 8l5 5 5-5H5z" />
            </svg>
            {isMarketHovered && (
                <div className="nav-dropdown-content">

                <Link href="/tokenomics">
                    <a style={{ textDecoration: 'none' }}>
                    <div className="nav-body-item">
                        <div className="dropdown-icon">
                        <Image src="/assets/whitepaper.png" alt="Whitepaper Icon" width={40} height={40} />
                        </div>
                        <div className="dropdown-content">
                        <div className="dropdown-title">
                            Whitepaper
                        </div>
                        <div className="dropdown-subtitle">
                            Dive into the detailed documentation about PINX’s goals and technology.
                        </div>
                        </div>
                        <div className="dropdown-svg">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                            <path d="M8 5l5 5 -5 5V5z" />
                        </svg>
                        </div>
                    </div>
                    </a>
                </Link>
                
                <Link href="/tokenomics">
                    <a style={{ textDecoration: 'none' }}>
                    <div className="nav-body-item">
                        <div className="dropdown-icon">
                        <Image src="/assets/tokenomics.gif" alt="Tokenomics Icon" width={40} height={40} />
                        </div>
                        <div className="dropdown-content">
                        <div className="dropdown-title">
                            Tokenomics
                        </div>
                        <div className="dropdown-subtitle">
                            Learn more about the supply and distribution of PINX tokens.
                        </div>
                        </div>
                        <div className="dropdown-svg">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                            <path d="M8 5l5 5 -5 5V5z" />
                        </svg>
                        </div>
                    </div>
                    </a>
                </Link>

                <Link href="/roadmap">
                    <a style={{ textDecoration: 'none' }}>
                    <div className="nav-body-item">
                        <div className="dropdown-icon">
                        <Image src="/assets/roadmap.png" alt="Roadmap Icon" width={40} height={40} />
                        </div>
                        <div className="dropdown-content">
                        <div className="dropdown-title">
                            Roadmap
                        </div>
                        <div className="dropdown-subtitle">
                            Check out the future development plans for PINX.
                        </div>
                        </div>
                        <div className="dropdown-svg">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                            <path d="M8 5l5 5 -5 5V5z" />
                        </svg>
                        </div>
                    </div>
                    </a>
                </Link>

                </div>
            )}
            </div>




            <div className={`nav-dropdown-container ${isSupportHovered ? 'modalSelected' : ''}`}
            onMouseEnter={() => setIsSupportHovered(true)}
            onMouseLeave={() => setIsSupportHovered(false)}>
            Support
            <svg className={`nav-dropdown-arrow ${isSupportHovered ? 'rotate' : ''}`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                <path d="M5 8l5 5 5-5H5z" />
            </svg>
            {isSupportHovered && (
                <div className="nav-dropdown-content">

                <Link href="/support">
                    <a style={{ textDecoration: 'none' }}>
                    <div className="nav-body-item">
                        <div className="dropdown-icon">
                        <Image src="/assets/technical-support.png" alt="Support Icon" width={40} height={40} />
                        </div>
                        <div className="dropdown-content">
                        <div className="dropdown-title">
                            Get Support
                        </div>
                        <div className="dropdown-subtitle">
                            Reach out to our team for help or if you have any questions.
                        </div>
                        </div>
                        <div className="dropdown-svg">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                            <path d="M8 5l5 5 -5 5V5z" />
                        </svg>
                        </div>
                    </div>
                    </a>
                </Link>

                </div>
            )}
            </div>

                <div className={`nav-dropdown-container ${isCompaniesHovered ? 'modalSelected' : ''}`}
                onMouseEnter={() => setIsCompaniesHovered(true)}
                onMouseLeave={() => setIsCompaniesHovered(false)}>
                Companies
                <svg className={`nav-dropdown-arrow ${isCompaniesHovered ? 'rotate' : ''}`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                    <path d="M5 8l5 5 5-5H5z" />
                </svg>
                {isCompaniesHovered && (
                    <div className="nav-dropdown-content">

                    <Link href="/companies">
                        <a style={{ textDecoration: 'none' }}>
                        <div className="nav-body-item">
                            <div className="dropdown-icon">
                            <Image src="/assets/company.png" alt="Companies Icon" width={40} height={40} />
                            </div>
                            <div className="dropdown-content">
                            <div className="dropdown-title">
                                Explore Companies
                            </div>
                            <div className="dropdown-subtitle">
                                Discover the listed companies and their market performance.
                            </div>
                            </div>
                            <div className="dropdown-svg">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                <path d="M8 5l5 5 -5 5V5z" />
                            </svg>
                            </div>
                        </div>
                        </a>
                    </Link>

                    </div>
                )}
                </div>

                <div className={`nav-dropdown-container ${isNewsHovered ? 'modalSelected' : ''}`}
                onMouseEnter={() => setIsNewsHovered(true)}
                onMouseLeave={() => setIsNewsHovered(false)}>
                News
                <svg className={`nav-dropdown-arrow ${isNewsHovered ? 'rotate' : ''}`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                    <path d="M5 8l5 5 5-5H5z" />
                </svg>
                {isNewsHovered && (
                    <div className="nav-dropdown-content">

                    <Link href="/news">
                        <a style={{ textDecoration: 'none' }}>
                        <div className="nav-body-item">
                            <div className="dropdown-icon">
                            <Image src="/assets/news.png" alt="News Icon" width={40} height={40} />
                            </div>
                            <div className="dropdown-content">
                            <div className="dropdown-title">
                                Latest News
                            </div>
                            <div className="dropdown-subtitle">
                                Stay updated with the latest news and updates in the market.
                            </div>
                            </div>
                            <div className="dropdown-svg">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                <path d="M8 5l5 5 -5 5V5z" />
                            </svg>
                            </div>
                        </div>
                        </a>
                    </Link>

                    </div>
                )}
                </div>

                <div className={`nav-dropdown-container ${isAboutHovered ? 'modalSelected' : ''}`}
                onMouseEnter={() => setIsAboutHovered(true)}
                onMouseLeave={() => setIsAboutHovered(false)}>
                About
                <svg className={`nav-dropdown-arrow ${isAboutHovered ? 'rotate' : ''}`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                    <path d="M5 8l5 5 5-5H5z" />
                </svg>
                {isAboutHovered && (
                    <div className="nav-dropdown-content">

                    <Link href="/about">
                        <a style={{ textDecoration: 'none' }}>
                        <div className="nav-body-item">
                            <div className="dropdown-icon">
                            <Image src="/assets/information.png" alt="About Icon" width={40} height={40} />
                            </div>
                            <div className="dropdown-content">
                            <div className="dropdown-title">
                                About Us
                            </div>
                            <div className="dropdown-subtitle">
                                Learn more about PINX Global and our mission.
                            </div>
                            </div>
                            <div className="dropdown-svg">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                                <path d="M8 5l5 5 -5 5V5z" />
                            </svg>
                            </div>
                        </div>
                        </a>
                    </Link>

                    </div>
                )}
                </div>

                        </div>
                    </div>


      <div className="navbar-user">
        <div className="user-options">
          <div className="user-inside-options">
            <Link href="https://dexscreener.com/solana/6m32vkef22fzvv2nc69wnabdax7wdn4zsggwjujurqev" passHref>
              <a style={{ textDecoration: 'none' }}>
                <div className="price-holder-button">
                  <span className="pinx-text">$PINX</span> <span className="price-text">${pinxPrice}</span>
                </div>
              </a>
            </Link>
            <div className="navbar-separator"></div>
            <ConnectWalletButton />
            <div className="navbar-separator"></div>
          </div>
        </div>
        <div className="user-item">
          <div className="language-selector">
          <Image 
                src="/assets/globe.png"
                alt="Global Icon"
                className="global-icon"
                width={50}
                height={50}
                layout="fixed"
              />
          </div>
        </div>
      </div>




      <style jsx global>{`
      .nav-dropdown-container.modalSelected .nav-dropdown-content {
          max-height: 500px; 
          opacity: 1; 
      }

         .nav-dropdown-container {
          display: flex;
          align-items: center;
          position: relative;
          width: auto;
          padding: 0 10px;
          font-size: 21px;
          cursor: pointer;
          font-weight: 500;
          white-space: nowrap;
          transition: all 0.3s ease 0s;
          text-decoration: none !important;
          color: white;
          height: 100%;
          }


        .nav-dropdown-arrow {
          width: 15px;
          height: auto; 
          fill: currentColor; 
          margin-left: 5px; 
          vertical-align: middle; 
          transition: transform 0.3s ease;
        }
        
        .nav-dropdown-arrow.rotate {
          transform: rotate(180deg); /* Rotate arrow */
        }

            .nav-dropdown-content {
            display: flex;
            flex-direction: column;
            position: absolute;
            top: 100%; /* Directly below the navbar */
            left: 0;
            color: white;
            box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
            z-index: 1;
            width: auto;
            min-width: 160px;
            max-height: 0; /* Start with max-height 0 */
            overflow: hidden;
            transition: max-height 0.5s ease-out, opacity 0.5s ease;
            opacity: 0; /* Start with opacity 0 */
            }

            .nav-body-item {
            display: flex;
            align-items: center;
            cursor: pointer;
            height: 80px;
            transition: background 0.3s ease, box-shadow 0.3s ease; 
            
            /* Enhanced 3D Gradient from top to bottom */
            background: linear-gradient(to bottom, rgba(140, 20, 70, 1) 0%, rgba(80, 0, 40, 1) 100%);
            
            border-radius: 10px; /* Rounded corners */
            margin: 6px 10px;

            /* Stronger outer shadow for raised effect */
            box-shadow: 0 8px 15px rgba(0, 0, 0, 0.3), inset 0 3px 6px rgba(255, 255, 255, 0.2);
            }

            .nav-body-item:hover {
            /* Darker gradient on hover to mimic press effect */
            background: linear-gradient(to bottom, rgba(100, 0, 50, 1) 0%, rgba(60, 0, 30, 1) 100%);
            
            /* Pressed down effect with deeper shadow */
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5), inset 0 -2px 6px rgba(255, 255, 255, 0.1);
            }

            .dropdown-content {
              flex: 1 1;
            }

            .dropdown-icon {   
                width: 40px;
                padding-left: 10px;
                box-sizing: content-box;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-shrink: 0;
                margin: 0px 10px;
            }

            .dropdown-svg {   
              width: 40px;
              padding-left: 10px;
              box-sizing: content-box;
              display: flex;
              align-items: center;
              justify-content: center;
              flex-shrink: 0;
              fill: white;
              vertical-align: middle;
              transition: transform 0.3s ease; 
          }

            .dropdown-title {
              font-size: 20px;
              line-height: 20px;
              color: var(--hbg-header-main-txtcolor);
              margin-bottom: 4px;
            }

            .dropdown-subtitle {
              font-size: 14px;
              line-height: 16px;
              color: var(--hbg-header-main-txtcolor);
              opacity: .5;
              overflow: hidden;
              text-overflow: ellipsis;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              display: -webkit-box;
          }




          a, a:active, a:focus, a:hover {
            outline: none;
            text-decoration: none;
            color: white;
            transition: transform 0.3s ease;
          }
          a:hover {
            transform: scale(1);
          }



        .nav-holder {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        height: 60px;
        padding: 0 12px;
        background: linear-gradient(to bottom, rgba(80, 0, 0, 0.6), rgba(0, 0, 0, 0.6)); 
        color: #fff;
        position: relative;
        z-index: 1000;
        box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.3); 
        }
        .logo-nav-container {
          display: flex;
          align-items: center;
          height: 60px;
        position: relative;
        }
        .logo-text {
          font-weight: bold;
          font-size: 25px;
          color: #fff;
          margin: 0;
          white-space:nowrap;
        }
        .nav-links {
          display: flex;
          height: 100%;
          align-items: center;
          flex-direction: row;
        }



        .nav-separator {
          border: none;
          flex-shrink: 0;
          background: #D3D3D3;
          width: 1px;
          margin: 0 20px;
          height: 16px;
        }


        

                .navbar-separator {
        border: none;
        flex-shrink: 0;
        background: #D3D3D3;
        display: block;
        width: 1px;
        margin: 0px 20px 0px 18px;
        height: 16px !important;
        }


        .navbar-user {
        display: flex;
        align-items: center;
        }

        .user-options {
        display: flex;
        align-items: center;
        flex-direction: row;
        }

        .user-inside-options {
        display: flex;
        align-items: center;
        margin-right: 0px;
        flex-direction: row;
        width: auto;
        padding: 0px;
        justify-content: unset;
        }

        .user-item {
        color: rgb(29, 29, 29);
        cursor: pointer;
        padding: 0px;
        font-size: 14px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: auto;
        white-space: nowrap;
        text-decoration: none !important;
        background-clip: border-box !important;
        }

        .price-holder-button {
        width: unset;
        padding: 9px 12px;
        font-weight: 500;
        font-size: 19px;
        line-height: 130%;
        text-align: center;
        color: white;
        margin: 0px;
        transition: all 0.3s ease 0s;
        border: unset;
        border-radius: 24px;
        cursor: pointer;
        }

        .pinx-text {
        color: #ff69b4;
        text-shadow: 0 0 8px rgba(255, 105, 180, 0.7);
        }

        .price-text {
        color: #00ff00;
        text-shadow: 0 0 8px rgba(0, 255, 0, 0.7);
        }

        .language-selector {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
        background-color: white;
        border-radius: 20px;
        margin-left: 12px;
        background-clip: padding-box !important;
        }

        .global-icon {
        width: 25px;
        height: auto;
        fill: black;
        vertical-align: middle;
        }




         @media (max-width: 1500px) {
          .logo-text {
            font-size: 20px;
          }
        } 

        @media (max-width: 1420px) {
          .nav-dropdown-container {
            font-size: 17px;
          }
        }
          @media (max-width: 1350px) {
          .dropdown-title {
            font-size: 1.8vw; 
          }
          
          .dropdown-subtitle {
            font-size: 1vw;
          }
        }

        @media (max-width: 1300px) {
          .logo-text {
            font-size: 1.5vw;
          }
          .nav-dropdown-container {
            font-size: 13px;
                        padding: 0 6px;
          }
          .price-text {
            font-size: 1.5vw;
        }
                      .pinx-text {
            font-size: 1.5vw;
        }
        }

        @media (max-width: 1200px) {
          .nav-separator {
            display: block; 
          }
        }
        @media (max-width: 1150px) {
          .logo-text {
            font-size: 1.5vw;
            white-space: nowrap;
          }
        }
                  @media (max-width: 1050px) {
          .price-text {
            font-size: 1.3vw;
        }
                      .pinx-text {
            font-size: 1.3vw;
        }
            
        }



      `}</style>
    </div>
  );
};

export default LaptopNavbar;

