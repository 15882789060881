import React from 'react';
import { FaTwitter, FaTelegramPlane, FaYoutube, FaApple } from 'react-icons/fa';
import Image from 'next/image';
import Link from 'next/link';

const LaptopFooter: React.FC = () => {
  return (
    <>
      <div className="footer-wrapper">
        <div className="footer">
          <div className="footer-container">
            <div className="logo-section">
              <div className="logo-background">
                <Image
                  src="/assets/pinx-circle-logo.png"
                  alt="PINX Logo"
                  width={180}
                  height={180}
                  className="logo"
                />
              </div>
              <p className="tagline">Your Gateway to Decentralized Finance</p>

              <button className="download-button">
                <span className="apple-icon"><FaApple /></span> Download on the App Store
              </button>

              <div className="horizontal-icons">
                <a href="https://x.com/pinxtoken" target="_blank" rel="noopener noreferrer">
                  <FaTwitter className="icon" />
                </a>
                <a href="https://t.me/pinxglobalmarket" target="_blank" rel="noopener noreferrer">
                  <FaTelegramPlane className="icon" />
                </a>
                <a href="https://www.youtube.com/@pinxglobalmarkets" target="_blank" rel="noopener noreferrer">
                  <FaYoutube className="icon" />
                </a>
              </div>
            </div>

            <div className="pinx-info-container">
              <h3 className="section-title">$PINX Info</h3>
              <Link href="/tokenomics">
                <a className="pinx-info-link">Whitepaper</a>
              </Link>
              <Link href="/tokenomics">
                <a className="pinx-info-link">Tokenomics</a>
              </Link>
              <Link href="/roadmap">
                <a className="pinx-info-link">Roadmap</a>
              </Link>
            </div>

            <div className="buy-pinx-container">
              <h3 className="section-title">Buy $PINX</h3>
              <Link href="/swap">
                <a className="buy-pinx-link">Buy on our platform</a>
              </Link>
              <a href="https://dexscreener.com/solana/6m32vkef22fzvv2nc69wnabdax7wdn4zsggwjujurqev" className="buy-pinx-link" target="_blank" rel="noopener noreferrer">
                Buy on Dexscreener
              </a>
              <Link href="/#steps">
                <a className="buy-pinx-link">How to Buy</a>
              </Link>
            </div>

            <div className="social-icons-container">
              <h3 className="section-title">Social Links</h3>
              <a href="https://x.com/pinxtoken" target="_blank" rel="noopener noreferrer" className="social-link">
                Twitter
              </a>
              <a href="https://t.me/pinxglobalmarket" target="_blank" rel="noopener noreferrer" className="social-link">
                Telegram
              </a>
              <a href="https://www.youtube.com/@pinxglobalmarkets" target="_blank" rel="noopener noreferrer" className="social-link">
                YouTube
              </a>
            </div>

            <div className="companies-container">
              <h3 className="section-title">Companies</h3>
              <Link href="/companies">
                <a className="companies-link">Companies</a>
              </Link>
              <Link href="/news">
                <a className="companies-link">News</a>
              </Link>
              <span className="anchor-placeholder">Company</span>
            </div>

            <div className="about-us-container">
              <h3 className="section-title">About Us</h3>
              <Link href="/about">
                <a className="about-us-link">About</a>
              </Link>
              <Link href="/support">
                <a className="about-us-link">Support</a>
              </Link>
              <span className="anchor-placeholder">About</span>
            </div>
          </div>
        </div>
      </div>

      <style jsx>{`
            .footer-wrapper {
                background: linear-gradient(to bottom, rgba(80, 0, 0, 0.6), rgba(0, 0, 0, 0.6));
                box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.3);
                padding: 20px 0;
                width: 100%;
                border-top: 1px solid rgba(255, 255, 255, 0.2);
            }
            .footer {
                display: flex;
                justify-content: center;
                align-items: center;
            }
            .footer-container {
                display: flex;
                justify-content: space-between;
                align-items: flex-start;
                width: 70%;
                padding: 20px 0;
                flex-wrap: wrap;
                transition: width 0.3s ease;
            }

            /* Logo Section */
            .logo-section {
                display: flex;
                flex-direction: column;
                align-items: center;
            }
            .logo-background {
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 50%;
                box-shadow: 0 0 20px 8px rgba(249, 0, 143, 0.6), 0 0 40px rgba(139, 33, 223, 0.5);
            }
            .tagline {
                color: #ffffff;
                font-size: 18px;
                font-weight: bold;
                margin-top: 20px;
                text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.7);
                text-align: center;
            }

            /* Download Button */
            .download-button {
                width: 100%;
                padding: 15px 0;
                font-size: 16px;
                color: white;
                background: linear-gradient(to bottom, #222, #111);
                border: 1px solid rgba(255, 255, 255, 0.2);
                border-radius: 8px;
                background-clip: padding-box;
                backdrop-filter: blur(10px);
                transition: all 0.3s ease;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-bottom: 8px;
            }

            .download-button:hover {
                background: linear-gradient(to bottom, #333, #222);
            }

            .apple-icon {
                margin-right: 6px;
                font-size: 24px;
            }

            .horizontal-icons {
                display: flex;
                justify-content: space-between;
                gap: 25px;
                margin-top: 10px;
                width: 90%;
                font-size: 32px;
            }
            .icon {
                width: 70px;
                height: 70px;
                color: #ffffff;
            }

            /* Section styles */
            .section-title {
                font-size: 22px;
                font-weight: bold;
                text-decoration: underline;
                color: #ffffff;
                text-align: center;
                margin-bottom: 15px;
            }

            /* Links and container styles */
            .social-icons-container,
            .pinx-info-container,
            .buy-pinx-container,
            .about-us-container,
            .companies-container {
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 15px;
            }
            .social-link,
            .pinx-info-link,
            .buy-pinx-link,
            .about-us-link,
            .companies-link,
            .anchor-placeholder {
                font-size: 18px;
                text-decoration: none;
                color: #ffffff;
                font-weight: bold;
            }

            /* Anchor placeholder for alignment */
            .anchor-placeholder {
                display: inline-block;
                visibility: hidden;
                font-size: 18px;
                font-weight: bold;
            }

            @media (max-width: 1550px) {
                .footer-container {
                width: 80%;
                }
                .logo-background {
                box-shadow: 0 0 15px 6px rgba(249, 0, 143, 0.6), 0 0 30px rgba(139, 33, 223, 0.5);
                }
                .tagline {
                font-size: 16px;
                }
                .apple-icon {
                font-size: 20px;
                }
                .icon {
                width: 60px;
                height: 60px;
                }
                .section-title {
                font-size: 20px;
                }
                .social-link, .pinx-info-link, .buy-pinx-link, .about-us-link, .companies-link {
                font-size: 16px;
                }
            }

            @media (max-width: 1200px) {
                .footer-container {
                width: 90%;
                }
                .logo-background {
                box-shadow: 0 0 10px 5px rgba(249, 0, 143, 0.6), 0 0 20px rgba(139, 33, 223, 0.5);
                }
                .tagline {
                font-size: 14px;
                }
                .apple-icon {
                font-size: 18px;
                }
                .icon {
                width: 50px;
                height: 50px;
                }
                .section-title {
                font-size: 18px;
                }
                .social-link, .pinx-info-link, .buy-pinx-link, .about-us-link, .companies-link {
                font-size: 14px;
                }
            }
            `}</style>
    </>
  );
};

export default LaptopFooter;
