import React from 'react';
import { FaTwitter, FaTelegramPlane, FaYoutube, FaApple } from 'react-icons/fa';
import Image from 'next/image';
import Link from 'next/link';

const MobileFooter: React.FC = () => {
  return (
    <>
      <div className="footer-wrapper">
        <div className="footer">
          <div className="footer-container">
            <div className="logo-info-wrapper">
              <div className="logo-section">
                <div className="logo-background">
                  <Image
                    src="/assets/pinx-circle-logo.png"
                    alt="PINX Logo"
                    width={120}
                    height={120}
                    className="logo"
                  />
                </div>
                <p className="tagline">Your Gateway to Decentralized Finance</p>

                <button className="download-button">
                  <span className="apple-icon"><FaApple /></span> Download on the App Store
                </button>

                <div className="horizontal-icons">
                  <a href="https://x.com/pinxtoken" target="_blank" rel="noopener noreferrer" className="icon-link">
                    <FaTwitter className="icon" />
                  </a>
                  <a href="https://t.me/pinxglobalmarket" target="_blank" rel="noopener noreferrer" className="icon-link">
                    <FaTelegramPlane className="icon" />
                  </a>
                  <a href="https://www.youtube.com/@pinxglobalmarkets" target="_blank" rel="noopener noreferrer" className="icon-link">
                    <FaYoutube className="icon" />
                  </a>
                </div>
              </div>

              <div className="info-section-row">
                <div className="pinx-info-container">
                  <h3 className="section-title">$PINX Info</h3>
                  <Link href="/tokenomics"><a className="pinx-info-link">Whitepaper</a></Link>
                  <Link href="/tokenomics"><a className="pinx-info-link">Tokenomics</a></Link>
                  <Link href="/roadmap"><a className="pinx-info-link">Roadmap</a></Link>
                </div>

                <div className="buy-pinx-container">
                  <h3 className="section-title">Buy $PINX</h3>
                  <Link href="/swap"><a className="buy-pinx-link">Buy on our platform</a></Link>
                  <a href="https://dexscreener.com/solana/6m32vkef22fzvv2nc69wnabdax7wdn4zsggwjujurqev" className="buy-pinx-link" target="_blank" rel="noopener noreferrer">Buy on Dexscreener</a>
                  <Link href="/#steps"><a className="buy-pinx-link">How to Buy</a></Link>
                </div>

                <div className="companies-container">
                  <h3 className="section-title">Companies</h3>
                  <Link href="/companies"><a className="companies-link">Companies</a></Link>
                  <Link href="/news"><a className="companies-link">News</a></Link>
                  <span className="anchor-placeholder">Company</span>
                </div>

                <div className="about-us-container">
                  <h3 className="section-title">About Us</h3>
                  <Link href="/about"><a className="about-us-link">About</a></Link>
                  <Link href="/support"><a className="about-us-link">Support</a></Link>
                  <span className="anchor-placeholder">About</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <style jsx>{`
        .footer-wrapper {
          background: linear-gradient(to bottom, rgba(80, 0, 0, 0.6), rgba(0, 0, 0, 0.6));
          padding: 20px 0;
          width: 100%;
          border-top: 1px solid rgba(255, 255, 255, 0.2);
        }
        .footer {
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .footer-container {
          width: 90%;
          padding: 20px 0;
        }

        .logo-info-wrapper {
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
          flex-wrap: wrap;
          width: 100%;
        }

        /* Logo Section */
        .logo-section {
          display: flex;
          flex-direction: column;
          align-items: center;
        }
        .logo-background {
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 50%;
          box-shadow: 0 0 15px 6px rgba(249, 0, 143, 0.6), 0 0 30px rgba(139, 33, 223, 0.5);
        }
        .tagline {
          color: #ffffff;
          font-size: 14px;
          font-weight: bold;
          margin-top: 10px;
          text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.7);
          text-align: center;
        }

        .download-button {
          width: 100%;
          padding: 10px 0;
          font-size: 14px;
          color: white;
          background: linear-gradient(to bottom, #222, #111);
          border: 1px solid rgba(255, 255, 255, 0.2);
          border-radius: 8px;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-bottom: 10px;
        }
        .download-button:hover {
          background: linear-gradient(to bottom, #333, #222);
        }
        .apple-icon {
          margin-right: 5px;
          font-size: 18px;
        }

        .horizontal-icons {
          display: flex;
          justify-content: space-between;
          gap: 20px;
          margin-top: 10px;
          font-size: 30px;
          width: 90%;
        }
        .icon {
          width: 50px;
          height: 50px;
          color: #ffffff;
        }

        .section-title {
          font-size: 16px;
          font-weight: bold;
          color: #ffffff;
          text-align: center;
          margin-bottom: 10px;
          text-decoration: underline;
        }

        .pinx-info-container,
        .buy-pinx-container,
        .about-us-container,
        .companies-container {
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 10px;
        }

        .pinx-info-link, 
        .buy-pinx-link, 
        .about-us-link, 
        .companies-link, 
        .anchor-placeholder {
          font-size: 14px;
          text-decoration: none;
          color: #ffffff;
          font-weight: bold;
        }

        .info-section-row {
          display: flex;
          justify-content: space-between;
          gap: 20px;
          width: 60%;
        }

        @media (max-width: 920px) {
          .section-title {
            font-size: 14px;
          }
          .pinx-info-link, 
          .buy-pinx-link, 
          .about-us-link, 
          .companies-link, 
          .anchor-placeholder {
            font-size: 12px;
          }
        }
        
        @media (max-width: 820px) {
          .info-section-row {
            gap: 20px;
          }
        }

        @media (max-width: 750px) {
          .logo-info-wrapper {
            flex-direction: column;
            align-items: center;
          }
          .info-section-row {
            margin-top: 25px;
            width: 100%;
          }
        }

        @media (max-width: 480px) {
          .info-section-row {
            flex-wrap: wrap;
            gap: 10px;
          }
          .pinx-info-container,
          .buy-pinx-container,
          .about-us-container,
          .companies-container {
            flex: 0 0 45%;
            margin-bottom: 10px;
          }
          .pinx-info-container,
          .buy-pinx-container,
          .about-us-container,
          .companies-container {
            margin-bottom: 20px;
          }
        }
      `}</style>
    </>
  );
};

export default MobileFooter;
